<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Update Slider

        <feather-icon icon="Edit2Icon" />
      </h4>

    </div>
    <validation-observer ref="addFormvalidate">
      <b-form
        class="mt-2"
        style="width: 60%"
        @submit.prevent="save"
      >
        <b-row>

          <b-col md="6">
            <b-form-group
              label="Slide Title"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Slide Title"
                rules=""
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="addForm.title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Slide Sub Title"
              label-for="blog-edit-slug"
              class="mb-2"
            >

              <validation-provider
                #default="{ errors }"
                name="Teaching Field Name"
                rules=""
              >
                <b-form-input
                  id="blog-edit-slug"
                  v-model="addForm.subtitle"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Button Text"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Slide Title"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="addForm.button"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Slide Timer"
              label-for="blog-edit-slug"
              class="mb-2"
            >

              <validation-provider
                #default="{ errors }"
                name="SlideTimer"
                rules="required|minValue:1|maxValue:25"
              >
                <b-form-input
                  id="blog-edit-timer"
                  v-model="addForm.timer"

                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Button URL"
              label-for="blog-edit-slug"
              class="mb-2"
            >

              <validation-provider
                #default="{ errors }"
                name="Teaching Field Name"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-slug"
                  v-model="addForm.link"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
              <b-form-group
                label="Order"
                label-for="blog-content"
                class="mb-2"
              >
                <b-form-input v-model="addForm.order" />
              </b-form-group>
            </b-col>
              <b-col cols="6">
            <b-form-group
              label="With Overlay"
              label-for="blog-content"
              class="mb-2"
            >
       
                      <b-form-checkbox
          v-model="addForm.with_overlay"
          switch
          value="1"
      unchecked-value="0"
        />
            </b-form-group>
           </b-col>
          <b-col
            cols="12"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Slide Image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewE2"
                      :src="`https://lpcentre.com/${addForm.image}`"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>

                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="refPreviewE2.src = Tabimage"
                  >
                    Remove Image
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block  mt-1">
                    <b-form-group
                      label="Slide Image "
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="TSlide Image"
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputE2"
                          v-model="addForm.image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="addForm.alt_img"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
            <div class="border rounded p-2">
              <h4 class="mb-1">
              Mobile Image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewEM"
                      :src="`https://lpcentre.com/${addForm.mobile_header_image}`"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0 col-12"
                    />
                  </b-media-aside>

                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="
                      refPreviewE3.src = Tabimage;
                      addForm.mobile_header_image = '';
                    "
                  >
                    Remove Image
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block mt-1">
                    <b-form-group
                      label="Mobile Image"
                      label-for="blog-tab-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="mobile_header_image"
                      >
                        <b-form-file
                          ref="refInputEM"
                          v-model="addForm.mobile_header_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab6"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="addForm.alt_mobile_header_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>

          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
            data-testid="update-button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="$router.go(-1)"
            >
              Back
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,

  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {   required, minValue, maxValue, email, } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive, computed } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import Vue from 'vue'
import store from '@/store'

export default {
  setup() {
    // const store = useStore();
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)

    const Tabimage = ref('media/svg/files/blank-image.svg')
    const { route } = useRouter()
    const { id } = route.value.params
    //   const productId = productSlug.substring(id.lastIndexOf('-') + 1)

    store.dispatch('slides/GetSlider', { id })
      .then(response => {
        store.commit('slides/GET_SLIDER', response?.data.data)
      })

    const Form = computed(() => store.state.slides.slider)
    const addForm = reactive(Form)

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const refPreviewEM = ref(null)
    const refInputEM= ref(null)
    const { inputImageRendererTab6 } = useInputImageRenderer(
      refInputEM,
      base64 => {
        refPreviewEM.value.src = base64
      },
    )
    const addFormvalidate = ref()

    const save = () => {
      const formData = new FormData()

      addFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append('title', addForm.value.title)
          formData.append('subtitle', addForm.value.subtitle)
          formData.append('image', addForm.value.image)
          formData.append('button', addForm.value.button)
          formData.append('timer', addForm.value.timer)
          formData.append('link', addForm.value.link)
          formData.append('alt_img', addForm.value.alt_img)
          formData.append('mobile_header_image', addForm.value.mobile_header_image)
          formData.append('alt_mobile_header_image', addForm.value.alt_mobile_header_image)
          formData.append('_method', 'put')
           formData.append('with_overlay', addForm.value.with_overlay)
          formData.append('order', addForm.value.order)
          store.dispatch('slides/UpdateSlider', { id, formData })
            .then(response => {
              Vue.swal({
                title: 'Slide Update ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }).catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }
    return {
      refInputEl,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,

      Tabimage,
      addForm,
      inputImageRenderer,
      addFormvalidate,
      id,
      Form,
      
      inputImageRendererTab6,
      refInputEM,
      refPreviewEM,

      save,
      required,
      email,
      minValue, maxValue,
    }
  },

  components: {
    BCard,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
